import React from "react";

import configs from "../../utils/configs";
import { useLogo } from "../styles/theme";

export function AppLogo({ className }: { className?: string }) {
  const logo = useLogo();

  return <img className={className} alt={configs.translation("app-name")} src={logo} />;
}
